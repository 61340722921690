import React, { useState, useEffect } from 'react';
import PairingNetworkGraph from './../components/pairing/PairingNetworkGraph';
import { generateStrategies } from '../components/pairing/algo';
import OptionsList from '../components/pairing/OptionsList';
import StockList from '../components/pairing/StockList';
import CashForm from '../components/pairing/CashForm';
import { Container } from '@mui/material';

const initialPortfolio = {
    stocks: [
        { id: 1, symbol: 'AAPL', quantity: 100, price: 150 },
    ],
    options: [
        { id: 1, symbol: 'AAPL', type: 'call', quantity: -1, strike: 160, expiration: new Date('2024-08-19') },
        { id: 2, symbol: 'AAPL', type: 'call', quantity: -1, strike: 220, expiration: new Date('2024-09-19') },
        { id: 3, symbol: 'AAPL', type: 'call', quantity: 1, strike: 220, expiration: new Date('2024-10-19') },
    ],
    cash: 1000,
};

const App = () => {
    const [portfolio, setPortfolio] = useState(initialPortfolio);
    const [strategies, setStrategies] = useState({});
    const [globalCash, setGlobalCash] = useState(portfolio.cash);

    useEffect(() => {
        const { allStrategies, finalCash } = generateStrategies(JSON.parse(JSON.stringify(portfolio)));
        setStrategies(allStrategies);
        setGlobalCash(finalCash);
        document.title = `Option pairing algorithm`; // Set your desired title for the /options-simulator page

    }, [portfolio]);

    const handleAddOption = (newOption) => {
        // if no stocks add stock
        const indexOfStock = portfolio.stocks.indexOf(s => s.symbol === newOption.symbol);
        if (indexOfStock < 0) {
            setPortfolio(prevPortfolio => ({
                ...prevPortfolio,
                options: [...prevPortfolio.options, newOption],
                stocks: [...prevPortfolio.stocks, { symbol: newOption.symbol, quantity: 0, price: 0 }]
            }));
        } else{
            setPortfolio(prevPortfolio => ({
                ...prevPortfolio,
                options: [...prevPortfolio.options, newOption]
            }));
        }
    };

    const handleUpdateOption = (updatedOption, index) => {
        setPortfolio(prevPortfolio => {
            const updatedOptions = [...prevPortfolio.options];
            updatedOptions[index] = updatedOption;
            return { ...prevPortfolio, options: updatedOptions };
        });
    };

    const handleDeleteOption = (index) => {
        setPortfolio(prevPortfolio => {
            const updatedOptions = prevPortfolio.options.filter((_, i) => i !== index);
            return { ...prevPortfolio, options: updatedOptions };
        });
    };

    const handleAddStock = (newStock) => {
        setPortfolio(prevPortfolio => ({
            ...prevPortfolio,
            stocks: [...prevPortfolio.stocks, newStock]
        }));
    };

    const handleUpdateStock = (updatedStock, index) => {
        setPortfolio(prevPortfolio => {
            const updatedStocks = [...prevPortfolio.stocks];
            updatedStocks[index] = updatedStock;
            return { ...prevPortfolio, stocks: updatedStocks };
        });
    };

    const handleDeleteStock = (index) => {
        setPortfolio(prevPortfolio => {
            const updatedStocks = prevPortfolio.stocks.filter((_, i) => i !== index);
            return { ...prevPortfolio, stocks: updatedStocks };
        });
    };

    const handleUpdateCash = (newCash) => {
        setPortfolio(prevPortfolio => ({
            ...prevPortfolio,
            cash: newCash
        }));
    };

    return (
        <Container>
            <OptionsList 
                options={portfolio.options} 
                onUpdateOption={handleUpdateOption} 
                onAddOption={handleAddOption}
                onDeleteOption={handleDeleteOption} 
            />
            <StockList 
                stocks={portfolio.stocks} 
                onUpdateStock={handleUpdateStock} 
                onAddStock={handleAddStock}
                onDeleteStock={handleDeleteStock} 
            />
            <CashForm cash={portfolio.cash} onUpdateCash={handleUpdateCash} />
            <PairingNetworkGraph strategies={strategies} globalCash={globalCash} />
        </Container>
    );
};

export default App;
