import React from 'react';
import { List, ListItem, IconButton, Typography, Box, Chip, Grid, useMediaQuery, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import styled from 'styled-components';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const Title = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    color: #464853;
    font-size: 16px;
    line-height: 1.7em;

    @media (max-width: 768px) {
        font-size: 14px; /* Smaller font size for smaller screens */
        margin-left: 10px; /* Adjusted margin for smaller screens */
    }
`;

const OptionsList = ({ options, onEditOption, deleteOption, selectedStock }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const formatExpiry = (expiry) => {
        const date = new Date(expiry);
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: '2-digit' });
    };

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {options.map((option) => (
                <ListItem
                    key={option.id}
                    sx={{
                        border: '1px solid #e0e0e0',
                        borderRadius: '4px',
                        mb: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        position: 'relative', // Ensure IconButton position works correctly
                        '&:hover': {
                            bgcolor: 'action.hover',
                            cursor: 'pointer',
                        },
                    }}
                    onClick={() => onEditOption(option)}
                >
                    {isMobile ? (
                        <Title>
                            {`${option.direction.toUpperCase()}-[Strike:${option.strike}$]-${option.type.toUpperCase()}-[Price:${option.premium}$]-[Qty:${option.quantity}]`}
                            {/* {`${option.direction.toUpperCase()} - [Strike: ${option.strike}] - ${option.type.toUpperCase()} - [Price: $${option.premium}] - [Qty: ${option.quantity}]`} */}

                        </Title>
                    ) : (
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} sm={4}>
                                <Box display="flex" alignItems="center">
                                    <Chip
                                        icon={option.direction === 'long' ? <CallMadeIcon /> : <CallReceivedIcon />}
                                        label={option.direction.toUpperCase()}
                                        color={option.direction === 'long' ? 'success' : 'error'}
                                        size="small"
                                        sx={{ mr: 1 }}
                                    />
                                    <Chip
                                        icon={option.type === 'call' ? <TrendingUpIcon /> : <TrendingDownIcon />}
                                        label={option.type.toUpperCase()}
                                        color={option.type === 'call' ? 'primary' : 'secondary'}
                                        size="small"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant="body2">
                                    Strike: ${option.strike}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant="body2">
                                    Quantity: {option.quantity}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant="body2">
                                    Premium: ${option.premium}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteOption(option.id);
                        }}
                        size="small"
                        color="error"
                        sx={{ position: 'absolute', right: 8, mt: 0 }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </ListItem>
            ))}
        </List>
    );
};

export default OptionsList;
