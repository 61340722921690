import React, { useState, useEffect } from 'react';
import { TextField, Button, Box } from '@mui/material';

const StockForm = ({ stock, onSave }) => {
  const [symbol, setSymbol] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (stock) {
      setSymbol(stock.symbol);
      setQuantity(stock.quantity);
      setPrice(stock.price);
    }
  }, [stock]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ symbol, quantity: parseInt(quantity), price: parseFloat(price) });
  };

  return (
    <form onSubmit={handleSubmit}>
            <Box sx={{ margin: '10px 0' }}></Box>

      <TextField label="Symbol" value={symbol} onChange={e => setSymbol(e.target.value)} required fullWidth />
      <Box sx={{ margin: '10px 0' }}></Box>
      <TextField label="Quantity" type="number" value={quantity} onChange={e => setQuantity(e.target.value)} required fullWidth />
      <Box sx={{ margin: '10px 0' }}></Box>
      <TextField label="Price" type="number" value={price} onChange={e => setPrice(e.target.value)} required fullWidth />
      <Box sx={{ margin: '10px 0' }}></Box>
      <Button type="submit" color="primary" variant="contained">Save</Button>
    </form>
  );
};

export default StockForm;
