import { useRef, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(...registerables, annotationPlugin);

export const predefinedStrategies = {
    'Bull Call Spread': [
        { id: 1, selectedStock: 'AAPL',strike: 100, premium: 10, type: 'call', quantity: 1, direction: 'long', expiry: '2024-07-19' },
        { id: 2, selectedStock: 'AAPL',strike: 110, premium: 5, type: 'call', quantity: 1, direction: 'short', expiry: '2024-07-19' }
    ],
    'Bull Put Spread': [
        { id: 1, selectedStock: 'AAPL',strike: 100, premium: 10, type: 'put', quantity: 1, direction: 'short', expiry: '2024-07-19' },
        { id: 2, selectedStock: 'AAPL',strike: 90, premium: 5, type: 'put', quantity: 1, direction: 'long', expiry: '2024-07-19' }
    ],
    'Bear Call Spread': [
    { id: 1, selectedStock: 'AAPL',strike: 100, premium: 10, type: 'call', quantity: 1, direction: 'short', expiry: '2024-07-19' },
    { id: 2, selectedStock: 'AAPL',strike: 110, premium: 5, type: 'call', quantity: 1, direction: 'long', expiry: '2024-07-19' }
],
'Bear Put Spread': [
    { id: 1, selectedStock: 'AAPL',strike: 100, premium: 10, type: 'put', quantity: 1, direction: 'long', expiry: '2024-07-19' },
    { id: 2, selectedStock: 'AAPL',strike: 90, premium: 5, type: 'put', quantity: 1, direction: 'short', expiry: '2024-07-19' }
],
    'Iron Condor': [
    { id: 1, selectedStock: 'AAPL',strike: 80, premium: 2, type: 'put', quantity: 1, direction: 'long', expiry: '2024-07-19' },
    { id: 2, selectedStock: 'AAPL',strike: 90, premium: 5, type: 'put', quantity: 1, direction: 'short', expiry: '2024-07-19' },
    { id: 3, selectedStock: 'AAPL',strike: 100, premium: 10, type: 'call', quantity: 1, direction: 'short', expiry: '2024-07-19' },
    { id: 4, selectedStock: 'AAPL',strike: 110, premium: 5, type: 'call', quantity: 1, direction: 'long', expiry: '2024-07-19' }
],
    'Straddle': [
        { id: 1,selectedStock: 'AAPL',strike: 100, premium: 15, type: 'call', quantity: 1, direction: 'long', expiry: '2024-07-19' },
        { id: 2,selectedStock: 'AAPL',strike: 100, premium: 15, type: 'put', quantity: 1, direction: 'long', expiry: '2024-07-19' }
    ],
   
    'Butterfly Spread': [
        { id: 1,selectedStock: 'AAPL',strike: 100, premium: 10, type: 'call', quantity: 1, direction: 'long', expiry: '2024-07-19' },
        { id: 2,selectedStock: 'AAPL',strike: 110, premium: 7, type: 'call', quantity: 2, direction: 'short', expiry: '2024-07-19' },
        { id: 3,selectedStock: 'AAPL',strike: 120, premium: 5, type: 'call', quantity: 1, direction: 'long', expiry: '2024-07-19' }
    ],
//     'Calendar Spread': [
//     { id: 1, strike: 100, premium: 10, type: 'call', quantity: 1, direction: 'long', expiry: '2024-09-19' },
//     { id: 2, strike: 100, premium: 5, type: 'call', quantity: 1, direction: 'short', expiry: '2024-07-19' }
// ],

    'Strangle': [
        { id: 1,selectedStock: 'AAPL',strike: 100, premium: 10, type: 'call', quantity: 1, direction: 'long', expiry: '2024-07-19' },
        { id: 2,selectedStock: 'AAPL',strike: 90, premium: 10, type: 'put', quantity: 1, direction: 'long', expiry: '2024-07-19' }
    ],
    // 'Collar': [
    //     { id: 1,strike: 100, premium: 10, type: 'call', quantity: 1, direction: 'short', expiry: '2024-07-19' },
    //     { id: 2,strike: 90, premium: 5, type: 'put', quantity: 1, direction: 'long', expiry: '2024-07-19' },
    //     { id: 3,strike: 0, premium: 0, type: 'stock', quantity: 100, direction: 'long', expiry: '2024-07-19' }
    // ],
//  'Iron Butterfly': [
//     { id: 1, strike: 100, premium: 10, type: 'call', quantity: 1, direction: 'short', expiry: '2024-07-19' }, // Short Call at the money
//     { id: 2, strike: 100, premium: 10, type: 'put', quantity: 1, direction: 'short', expiry: '2024-07-19' },  // Short Put at the money
//     { id: 3, strike: 110, premium: 5, type: 'call', quantity: 1, direction: 'long', expiry: '2024-07-19' },  // Long Call out of the money
//     { id: 4, strike: 90, premium: 5, type: 'put', quantity: 1, direction: 'long', expiry: '2024-07-19' }     // Long Put out of the money
// ],

    'Ratio Spread': [
        { id: 1,selectedStock: 'AAPL', strike: 100, premium: 10, type: 'call', quantity: 1, direction: 'long', expiry: '2024-07-19' },
        { id: 2,selectedStock: 'AAPL',strike: 110, premium: 5, type: 'call', quantity: 2, direction: 'short', expiry: '2024-07-19' }
    ]
};

const useOptionChart = (options, selectedStock, showOptions, showCumulative) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        const visibleOptions = showOptions ? options : options.filter(option => option.visible);

        const { data, chartOptions } = prepareChartData(options, visibleOptions, selectedStock, showCumulative);

        // const newChartInstance = new Chart(chartRef.current, {
        //     type: 'line',
        //     data: data,
        //     options: chartOptions,
        // });
        const newChartInstance = new Chart(chartRef.current, {
            type: 'line',
            data: data,
            options: {
                ...chartOptions,
                maintainAspectRatio: false, // Ensure the chart maintains its aspect ratio
                responsive: true,
                aspectRatio: 2, // You can adjust this to control the aspect ratio
            },
        });
        chartInstanceRef.current = newChartInstance;

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [options, selectedStock, showOptions, showCumulative]);

    return chartRef;
};

const prepareChartData = (options, visibleOptions, selectedStock, showCumulative) => {
    const breakEven = options.map(option => parseInt(option.strike) + parseInt(option.premium));
    const strikePrices = options.map(option => parseInt(option.strike));

    // Calculate min and max strike prices for adjusting x-axis scale
    const minStrike = Math.min(...strikePrices);
    const maxStrike = Math.max(...strikePrices);

    const minPrice = Math.min(...breakEven, minStrike) - 80;
    const maxPrice = Math.max(...breakEven, maxStrike) + 80;
    const stockPrices = Array.from({ length: 400 }, (_, i) => minPrice + i * (maxPrice - minPrice) / 399);

    const formatOptionName = (option) => {
        const optionType = option.type === 'call' ? 'C' : 'P';
        const directionSymbol = option.direction === 'long' ? '+' : '-';
        return `${directionSymbol}${selectedStock}${option.strike}${optionType}${option.expiry}`;
    };

    const calculateOptionPL = (option, stockPrice) => {
        const { strike, premium, type, quantity, direction } = option;
        const multiplier = direction === 'long' ? 1 : -1;

        let optionValue;
        if (type === 'call') {
            optionValue = Math.max(stockPrice - strike, 0);
        } else {
            optionValue = Math.max(strike - stockPrice, 0);
        }

        return (optionValue - premium) * quantity * multiplier;
    };

    const datasets = visibleOptions.map((option, index) => {
        const data = stockPrices.map(price => calculateOptionPL(option, price));

        return {
            label: formatOptionName(option),
            data: data,
            borderColor: `hsl(${index * 30}, 70%, 50%)`,
            backgroundColor: `hsla(${index * 30}, 70%, 50%, 0.2)`,
            fill: false,
            lineTension: 0,
            pointRadius: 0,
            pointHoverRadius: 10
        };
    });

    if (showCumulative) {
        const totalPL = stockPrices.map(price =>
            options.reduce((sum, option) => sum + calculateOptionPL(option, price), 0)
        );

        datasets.push({
            label: 'Total P&L',
            data: totalPL,
            borderColor: 'rgba(0, 0, 0, 1)',
            fill: true,
            lineTension: 1,
            borderWidth: 2,
            segment: {
                backgroundColor: ctx => ctx.p0.parsed.y < 0 ? 'rgba(255, 0, 0, 0.2)' : 'rgba(0, 255, 0, 0.2)',
            },
            pointRadius: 0,
            pointHoverRadius: 0
        });
    }

    const data = {
        labels: stockPrices,
        datasets: datasets,
    };

    const allValues = datasets.flatMap(dataset => dataset.data);
    const maxAbsValue = Math.max(...allValues.map(value => Math.abs(value)));

    const chartOptions = {
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    display: true,
                    text: 'Stock Price'
                },
                min: minPrice,
                max: maxPrice
            },
            y: {
                beginAtZero: true,
                title: {
                    // display: true,
                    // text: 'P&L'
                }
            },
        },
        plugins: {
            annotation: {
                annotations: {
                    horizontalLine: {
                        type: 'line',
                        yMin: 0,
                        yMax: 0,
                        borderColor: 'black',
                        borderWidth: 2,
                        label: {
                            content: 'Break Even',
                            enabled: true,
                            position: 'end',
                        },
                    },
                    ...strikePrices.map((strike, index) => ({
                        type: 'line',
                        yMin: -maxAbsValue,
                        yMax: maxAbsValue,
                        xMin: strike,
                        xMax: strike,
                        borderColor: `hsl(${index * 30}, 70%, 50%)`,
                        borderWidth: 2,
                        label: {
                            content: `Strike ${strike}`,
                            enabled: true,
                            position: 'top',
                        },
                    })),
                },
            },
        },
    };

    return { data, chartOptions };
};

export default useOptionChart;
