import React, { useState, useEffect } from 'react';

import VideoList from '../components/VideoList';
import styled from 'styled-components';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom'; // Import Link from react-router-dom
import { FaArrowLeft } from 'react-icons/fa';
const BASE_URL = 'https://monotryst.com';
const LevelPageContainer = styled.div`
  height: 100%;
`;

const TopSideContainer = styled.div`
  display: flex;
  align-items: center; 
  padding: 20px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const SearchBar = styled.input`
  padding: 10px;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 400px;
  @media (max-width: 768px) {
    width: calc(100% - 30px);
  }
`;

const Title = styled.div`
  margin-left: 20px;
  margin-bottom: 20px;
  color: #464853;
  font-size: 26px;
  color: #0077b6;
`;
const Empty = styled.div`
  // flex-grow: 1; // Fill the remaining space
`;

const LevelPage = () => {
  const { level, domain } = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetchVideos('');
    document.title = `Level ${level}`; // Set your desired title for the /options-simulator page

  }, [level, domain]);
 
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    fetchVideos(event.target.value);
  };

  const fetchVideos = async (query) => {
    const response = await axios.get(`${BASE_URL}/api/videos/${domain}/${level}`, {
      params: {
        search: query,
      },
    });
    setVideos(response.data.videos);
  };


  return (
    <LevelPageContainer>
      <TopSideContainer>
        <Link to={`/`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#464853', marginRight: '20px' }}>
          <FaArrowLeft style={{ marginRight: '5px' }} /> Return Home
        </Link>
        <SearchBar type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange} />
        <Empty />
      </TopSideContainer>
      {domain === 'algo' && level === 'beginner' && <Title>Level Beginner</Title>}
      {domain === 'algo' && level === 'intermediate' && <Title>Level Medium</Title>}
      {domain === 'algo' && level === 'advanced' && <Title>Level Hard</Title>}
      {domain === 'database' && level === 'beginner' && <Title>Database Courses</Title>}

      <VideoList videos={videos} />
    </LevelPageContainer>
  );
};

export default LevelPage;
