// src/App.js
import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PLChart from '../components/stock/pl-chart/PLChart';
import styled from 'styled-components';

const theme = createTheme();
const Title = styled.div`
  margin-left: 0px;
  margin-bottom: 20px;
  color: #464853;
  font-size: 26px;
  color: #0077b6;
`;

const ChartPage = () => {
    const [options, setOptions] = useState([]);
    const [selectedStock, setSelectedStock] = useState('AAPL');

    const addOption = (option) => {
        setOptions([...options, { ...option, id: Date.now() }]);
    };

    const updateOption = (updatedOption) => {
        setOptions(options.map(opt => opt.id === updatedOption.id ? updatedOption : opt));
    };

    const deleteOption = (id) => {
        setOptions(options.filter(opt => opt.id !== id));
    };

    useEffect(() => {
        // Update the document title
        document.title = "Options PL Chart"; // Set your desired title for the /options-simulator page
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <Box my={4}>
                    <Title>
                        Option P&L Chart
                    </Title>
                    <Grid>
                        <Paper>
                            <Box p={3}>
                                <PLChart
                                    setOptions={setOptions}
                                    options={options}
                                    updateOption={updateOption}
                                    deleteOption={deleteOption}
                                    addOption={addOption}
                                    selectedStock={selectedStock}
                                />
                            </Box>
                        </Paper>
                    </Grid>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default ChartPage;
