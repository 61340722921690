import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Modal } from '@mui/material';
import styled from 'styled-components';

const Title = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
  color: #0077b6;
  font-size: 26px;
`;

const CashForm = ({ cash, onUpdateCash }) => {
  const [newCash, setNewCash] = useState(cash);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdateCash(parseFloat(newCash));
    handleClose();
  };

  return (
    <Box onClick={handleOpen} sx={{
      marginTop: '10px',
      // marginBottom: '10px',
      paddingBottom: '20px',
      paddingTop: '10px',
      backgroundColor: 'white',
      borderRadius: '10px',
      cursor: 'pointer'
    }}>
      <Title>Cash</Title>
      <Box sx={{
        marginTop: '10px',
        marginLeft: '10px'
      }}>
        ${cash.toLocaleString()}
        </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Update Cash
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Cash"
              type="number"
              value={newCash}
              onChange={e => setNewCash(e.target.value)}
              required
              fullWidth
              sx={{ mt: 2 }}
            />
            <Button type="submit" color="primary" variant="contained" sx={{ mt: 2 }}>
              Update Cash
            </Button>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default CashForm;
