import React, { useRef } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { TextureLoader } from 'three';

const Sun = ({size}) => {
  const sunRef = useRef();
  const sunTexture = useLoader(TextureLoader, 'textures/sun.jpg');

  useFrame(() => {
    if (sunRef.current) {
      sunRef.current.rotation.y += 0.002; // Rotate the Sun around its Y-axis
    }
  });

  if (!sunTexture) {
    return null;
  }

  return (
    <mesh ref={sunRef}>
      <sphereGeometry args={[size, 64, 64]} />
      <meshStandardMaterial
        map={sunTexture}
        emissiveMap={sunTexture}
        emissiveIntensity={1.5}
        emissive="orange"
      />
    </mesh>
  );
};

export default Sun;
