import React from 'react';
import Planet from './Planet';
import Sun from './Sun';

const SolarSystem = () => {
  return (
    <>
      <Sun size={10} />
      <Planet
        name={"Mercury"}
        orbitTime={0.24}
        radius={0.2}
        orbitRadius={13}
        image={'textures/mercury.jpg'}
      />
      <Planet
        name={"Venus"}
        orbitTime={0.24}
        radius={0.615}
        orbitRadius={16}
        image={'textures/venus.jpg'}
      />
      <Planet
        name={"Earth"}
        orbitTime={1}
        radius={0.7}
        orbitRadius={20}
        image={'textures/earth.jpg'}
        moons={[
          {
            name: 'Moon',
            image: 'textures/moon.jpg',
            radius: 0.27,
            orbitRadius: 1.5,
            orbitTime: 0.0748, // Equivalent to 27.3 days scaled to 1 minute for Earth
            rotationSpeed: 0.01,
          },
        ]}
      />
      <Planet
        name={"Mars"}
        orbitTime={1.876}
        radius={0.6}
        orbitRadius={25}
        image={'textures/mars.jpg'}
      />
      <Planet
        name={"Jupiter"}
        orbitTime={11.861}
        radius={1.5}
        orbitRadius={30}
        image={'textures/jupiter.jpg'}
      />
      <Planet
        name={"Saturn"}
        orbitTime={29.457}
        radius={1.2}
        orbitRadius={35}
        image={'textures/saturn.jpg'}
      />
      <Planet
        name={"Uranus"}
        orbitTime={84.016}
        radius={0.7}
        orbitRadius={40}
        image={'textures/uranus.jpg'}
      />
      <Planet
        name={"neptune"}
        orbitTime={164.788}
        radius={0.7}
        orbitRadius={45}
        image={'textures/neptune.jpg'}
      />
    </>
  );
};

export default SolarSystem;