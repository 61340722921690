import React, { useRef } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { TextureLoader } from 'three';
import { Text } from '@react-three/drei';
import OrbitLine from './OrbitLine';

const Moon = ({ name, image, radius, orbitRadius, orbitTime, rotationSpeed = 0.01 }) => {
  const moonRef = useRef();
  const groupRef = useRef();
  const texture = useLoader(TextureLoader, image);

  // Convert orbitTime in minutes to orbitSpeed in radians per second
  const orbitSpeed = (2 * Math.PI) / (orbitTime * 60);

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();

    if (moonRef.current) {
      // Self-rotation
      moonRef.current.rotation.y += rotationSpeed;
    }

    if (groupRef.current) {
      // Orbit around the planet
      groupRef.current.position.x = orbitRadius * Math.cos(elapsedTime * orbitSpeed);
      groupRef.current.position.z = orbitRadius * Math.sin(elapsedTime * orbitSpeed);
    }
  });

  if (!texture) {
    return null;
  }

  return (
    <>
      <OrbitLine orbitRadius={orbitRadius} />
      <group ref={groupRef}>
        <mesh ref={moonRef}>
          <sphereGeometry args={[radius, 32, 32]} />
          <meshStandardMaterial map={texture} />
        </mesh>
        <Text
          position={[0, radius + 0.5, 0]} // Position label above the moon
          fontSize={radius / 2} // Smaller font size for the moon
          color="white"
          anchorX="center"
          anchorY="middle"
        >
          {name}
        </Text>
      </group>
    </>
  );
};

export default Moon;