const portfolio = {
    stocks: [
        { symbol: 'AAPL', quantity: 200, price: 150 },
        { symbol: 'TSLA', quantity: 150, price: 240 },
    ],
    options: [
        { symbol: 'AAPL', type: 'call', quantity: -1, strike: 160, expiration: new Date('2024-08-19') },
        { symbol: 'TSLA', type: 'put', quantity: -2, strike: 220, expiration: new Date('2024-09-19') },
        { symbol: 'TSLA', type: 'call', quantity: -1, strike: 750, expiration: new Date('2024-09-19') },
        { symbol: 'AAPL', type: 'put', quantity: 1, strike: 100, expiration: new Date('2024-10-19') },
        { symbol: 'AAPL', type: 'call', quantity: 2, strike: 170, expiration: new Date('2024-11-19') },
    ],
    cash: 100000,
};

// Group portfolio items by their underlying symbol
function groupBySymbol(portfolio) {
    const grouped = {};

    portfolio.stocks.forEach(stock => {
        if (!grouped[stock.symbol]) grouped[stock.symbol] = { stock: stock, options: [] };
    });

    portfolio.options.forEach(option => {
        if (!grouped[option.symbol]) grouped[option.symbol] = { stock: null, options: [] };
        grouped[option.symbol].options.push(option);
    });

    return grouped;
}

// Check if a short option can be covered
function canCover(shortOption, longOption, stock, cash) {
    stock = stock || {quantity : 0}
    const requiredQuantity = Math.abs(shortOption.quantity);

    if (longOption) {
        return (
            longOption.type === shortOption.type &&
            longOption.expiration >= shortOption.expiration &&
            longOption.quantity >= requiredQuantity
        );
    }
    if (shortOption.type === 'call' && stock && stock.quantity >= requiredQuantity * 100) {
        return true;
    }

    if (shortOption.type === 'put' && cash >= shortOption.strike * requiredQuantity * 100) {
        return true;
    }

    return false;
}

// DFS to generate all possible pairs and uncover naked shorts
function dfs(options, stock, cash, index, pairs, unpaired, nakedShorts) {
    stock = stock || {quantity : 0}
    if (index >= options.length) {
        return { pairs, unpaired, nakedShorts, stock };
    }

    const currentOption = options[index];
    let paired = false;

    if (currentOption.quantity < 0) { // Short position
        // Try to pair with a later long option from the unpaired list
        for (let i = 0; i < unpaired.length; i++) {
            const option = unpaired[i];
            if (option.quantity > 0 && canCover(currentOption, option, stock, cash)) {
                const pairQuantity = Math.min(Math.abs(currentOption.quantity), option.quantity);

                // Create a pair with the current short and part of the long option
                pairs.push({
                    short: { ...currentOption, quantity: -pairQuantity },
                    long: { ...option, quantity: pairQuantity }
                });

                // Reduce the quantities of the current short and long options
                currentOption.quantity += pairQuantity;
                option.quantity -= pairQuantity;

                // If the current long option still has remaining quantity, keep it in unpaired
                if (option.quantity > 0) {
                    unpaired[i] = option;
                } else {
                    unpaired.splice(i, 1); // Remove paired long option from unpaired
                }

                paired = true;
                break;
            }
        }

        // Try to pair with stock
        if (!paired && currentOption.type === 'call' && stock.quantity >= Math.abs(currentOption.quantity) * 100) {
            pairs.push({
                short: currentOption,
                stock: { ...stock, quantity: Math.abs(currentOption.quantity) * 100 }
            });
            stock.quantity -= Math.abs(currentOption.quantity) * 100;
            paired = true;
        }

        // Try to pair with cash
        if (!paired && currentOption.type === 'put' && cash >= currentOption.strike * Math.abs(currentOption.quantity) * 100) {
            pairs.push({
                short: currentOption,
                cash: currentOption.strike * Math.abs(currentOption.quantity) * 100
            });
            cash -= currentOption.strike * Math.abs(currentOption.quantity) * 100;
            paired = true;
        }

        // If not paired, mark as naked
        if (!paired) {
            nakedShorts.push(currentOption);
        }
    } else { // Long position
        // Try to pair with an earlier short option from the naked shorts
        for (let i = 0; i < nakedShorts.length; i++) {
            const shortOption = nakedShorts[i];
            if (canCover(shortOption, currentOption, stock, cash)) {
                const pairQuantity = Math.min(Math.abs(shortOption.quantity), currentOption.quantity);

                // Create a pair with the current long and part of the short option
                pairs.push({
                    short: { ...shortOption, quantity: -pairQuantity },
                    long: { ...currentOption, quantity: pairQuantity }
                });

                // Reduce the quantities of the current short and long options
                shortOption.quantity += pairQuantity;
                currentOption.quantity -= pairQuantity;

                // If the current long option still has remaining quantity, add it back to unpaired
                if (currentOption.quantity > 0) {
                    unpaired.push(currentOption);
                }

                // If the current short option is fully covered, remove it from naked shorts
                if (shortOption.quantity === 0) {
                    nakedShorts.splice(i, 1);
                } else {
                    nakedShorts[i] = shortOption;
                }

                paired = true;
                break;
            }
        }

        // If not paired, add to unpaired
        if (!paired) {
            unpaired.push(currentOption);
        }
    }

    return dfs(options, stock, cash, index + 1, pairs, unpaired, nakedShorts);
}

// Function to generate all possible strategies for each underlying security
export function generateStrategies(portfolio) {
    const groupedBySymbol = groupBySymbol(portfolio);
    const allStrategies = {};
    let remainingCash = portfolio.cash; // Track remaining cash globally

    for (const symbol in groupedBySymbol) {
        const { stock, options } = groupedBySymbol[symbol];
        // Make a copy of stock and options for this symbol
        const result = dfs(options, stock, remainingCash, 0, [], [], []);
        allStrategies[symbol] = {
            pairs: result.pairs,
            unpaired: result.unpaired,
            nakedShorts: result.nakedShorts,
            stock: result.stock
        };
        remainingCash = result.pairs.reduce((acc, pair) => {
            return acc - (pair.cash || 0);
        }, remainingCash);
    }

    // Return the strategies with the final remaining cash state
    return { allStrategies, finalCash: remainingCash };
}

// Find all possible strategies
const { allStrategies, finalCash } = generateStrategies(portfolio);

// Display results
console.log('All Strategies:', JSON.stringify(allStrategies, null, 2));
console.log('Final Global Cash:', finalCash);
