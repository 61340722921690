import React from 'react';
import VideoItem from './VideoItem';
import styled from 'styled-components';

const VideoListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const Title = styled.div`
  margin-left: 20px;
  margin-bottom: 20px;
  // color: #464853;
  font-size: 16px;
  // color: #0077b6;
position: absolute;
margin-left: calc(50% - 60px)
 
`;

const VideoList = ({ videos }) => {
  return (
    <VideoListContainer>
      {(videos || [])
        .map(video => (
          <VideoItem key={video.id} video={video} />
        ))}
      {(videos || []).length === 0 && <Title>
        No assignement found
      </Title>}
    </VideoListContainer>
  );
};

export default VideoList;
