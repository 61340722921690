import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'; // Import Link from react-router-dom
import axios from 'axios';
import styled from 'styled-components';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FaCopy, FaArrowLeft } from 'react-icons/fa';
const BASE_URL = 'https://monotryst.com';

const VideoDetailContainer = styled.div`
  padding: 20px;
  max-width: 740px;
  margin: auto;
  margin-top: 20px;
  border-radius: 20px;
`;

const VideoFrame = styled.iframe`
  margin-bottom: 20px;
`;

const CodeContainer = styled.div`
  text-align: left;
  background-color: rgb(30, 30, 30);
  padding: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  position: relative;
`;

const CodeTitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 10px;
`;

const CopyButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 0.9em;

  &:hover {
    background-color: #0056b3;
  }
`;
const AssignmentContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: left;
  background: #1e1e1e;  /* Dark background for assignment container */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Space = styled.div`
  height: 10px;
`;

const Frame = styled.div`
  // border: solid 1px;
  & > iframe  {
    margin: 0;
  }
`;

const AssignmentText = styled.pre`
  color: white;  /* White text */
  line-height: 1.5;
  font-size: 14px;
  white-space: pre-line;
`;

const VideoDetailPage = () => {
  const { videoid } = useParams();
  const [video, setVideo] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    fetchVideo(videoid);
  }, [videoid]);

  const fetchVideo = async () => {
    const response = await axios.get(`${BASE_URL}/api/videos/${videoid}`);
    setVideo(response.data.video);
    document.title = `Problem to solve`; // Set your desired title for the /options-simulator page
  };

  if (!video) return <div>Loading...</div>;

  const handleCopyCode = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(video.solution)
        .then(() => {
          setCopySuccess('Code copied to clipboard!');
          setTimeout(() => setCopySuccess(''), 2000);  // Reset after 2 seconds
        })
        .catch(err => {
          console.error('Failed to copy code: ', err);
        });
    } else {
      // Fallback method for older browsers
      const textArea = document.createElement("textarea");
      textArea.value = video.solution;
      textArea.style.position = "fixed";  // Avoid scrolling to bottom
      textArea.style.left = "-9999px";    // Move off screen
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        setCopySuccess('Code copied to clipboard!');
        setTimeout(() => setCopySuccess(''), 2000);  // Reset after 2 seconds
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    }
  };

  return (
    <VideoDetailContainer>
      <Link to={`/level/${video.domain}/${video.level}`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#464853' }}>
        <FaArrowLeft style={{ marginRight: '5px' }} /> Return to Video List
      </Link>
      <CodeTitle>{video.title}</CodeTitle>
      <Space />
      <Frame>
        <VideoFrame
          title={video.title}
          width="100%"
          height="420"
          src={`https://www.youtube.com/embed/${video.videoid}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </VideoFrame>
      </Frame>

      {/* <Title>{snippet.title}</Title> */}

      <CodeTitle>Assignment:</CodeTitle>
      <AssignmentContainer>
        <AssignmentText>{video.description}</AssignmentText>
      </AssignmentContainer>

      <CodeTitle>Code Solution:</CodeTitle>
      <CodeContainer>
        <CopyButton onClick={handleCopyCode}>
          <FaCopy />
        </CopyButton>
        <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
          {video.solution}
        </SyntaxHighlighter>
      </CodeContainer>

    </VideoDetailContainer>
  );
};

export default VideoDetailPage;
