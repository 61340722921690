import React, { useState } from 'react';
import styled from 'styled-components';
const BASE_URL = 'https://monotryst.com';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
//   min-height: 100vh;
  padding: 30px;
  background-color: #f0f0f0;
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

const Title = styled.h1`
  text-align: center;
  color: #3498db;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-top: 15px;
  font-weight: bold;
  color: #333;
`;

const Input = styled.input`
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
`;

const TextArea = styled.textarea`
  padding: 12px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  resize: none;
  overflow: hidden;
`;

const Button = styled.button`
  margin-top: 25px;
  padding: 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const Message = styled.div`
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  color: ${props => props.error ? 'red' : 'green'};
`;

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [submitMessage, setSubmitMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === 'message') {
      autoResizeTextarea(e.target);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Client-side validation
    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
      setSubmitMessage('All fields are required.');
      setIsError(true);
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/emails/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': 'YOUR_CSRF_TOKEN' // Replace with your actual CSRF token
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setSubmitMessage('Message sent successfully!');
        setIsError(false);
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        setSubmitMessage('Error sending message. Please try again.');
        setIsError(true);
      }
    } catch (error) {
      setSubmitMessage('Error sending message. Please try again.');
      setIsError(true);
    }
  };

  const autoResizeTextarea = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  return (
    <Container>
      <FormWrapper>
        <Title>Contact Us</Title>
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="name">Name:</Label>
          <Input 
            type="text" 
            id="name" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            required 
          />
          
          <Label htmlFor="email">Email:</Label>
          <Input 
            type="email" 
            id="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />
          
          <Label htmlFor="subject">Subject:</Label>
          <Input 
            type="text" 
            id="subject" 
            name="subject" 
            value={formData.subject} 
            onChange={handleChange} 
            required 
          />
          
          <Label htmlFor="message">Message:</Label>
          <TextArea 
            id="message" 
            name="message" 
            rows="5" 
            value={formData.message} 
            onChange={handleChange} 
            required 
          />
          
          <Button type="submit" disabled={!formData.name || !formData.email || !formData.subject || !formData.message}>
            Send Message
          </Button>
        </Form>
        <Message error={isError}>{submitMessage}</Message>
      </FormWrapper>
    </Container>
  );
};

export default ContactPage;
