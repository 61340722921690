import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import OptionForm from './OptionForm';
import dayjs from 'dayjs';
import styled from 'styled-components';

const Title = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
  color: #464853;
  font-size: 26px;
  color: #0077b6;
`;

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const OptionsList = ({ options, onUpdateOption, onDeleteOption, onAddOption }) => {
    const [open, setOpen] = useState(false);
    const [currentOption, setCurrentOption] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const handleClickOpen = (option, index) => {
        setCurrentOption(option);
        setCurrentIndex(index);
        setIsEditing(true);
        setOpen(true);
    };

    const handleAddClickOpen = () => {
        setCurrentOption({ symbol: '', type: '', quantity: 0, strike: 0, expiration: new Date() });
        setCurrentIndex(null);
        setIsEditing(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentOption(null);
        setCurrentIndex(null);
    };

    const handleSave = (updatedOption) => {
        if (isEditing) {
            onUpdateOption(updatedOption, currentIndex);
        } else {
            updatedOption.id = getRandomInt();
            onAddOption(updatedOption);
        }
        handleClose();
    };

    return (
        <Box sx={{
            paddingTop: '10px',
            backgroundColor: 'white',
            borderRadius: '10px'
        }}>
            <Title>Options</Title>
            <List>
                {options.map((option, index) => (
                    <ListItem key={index} button onClick={() => handleClickOpen(option, index)}>
                        <ListItemText primary={`${option.symbol} ${option.type} ${option.quantity} ${option.strike} ${dayjs(option.expiration).format('MMM D, YYYY')}`} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => onDeleteOption(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}

            </List>
            <Box sx={{
                padding: '10px'
            }}>
                <Button fullWidth variant="contained" color="primary" onClick={handleAddClickOpen}>Add Option</Button>
            </Box>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{isEditing ? 'Edit Option' : 'Add Option'}</DialogTitle>
                <DialogContent>
                    {currentOption && <OptionForm option={currentOption} onSave={handleSave} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default OptionsList;
