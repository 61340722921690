import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Select, MenuItem } from '@mui/material';

const stocks = ['AAPL', 'MSFT', 'GOOGL', 'AMZN', 'TSLA', 'FB', 'JPM', 'JNJ'];

const OptionForm = ({ option, onSave }) => {
  const [symbol, setSymbol] = useState('AAPL');
  const [type, setType] = useState('call');
  const [quantity, setQuantity] = useState(0);
  const [strike, setStrike] = useState(0);
  const [expiration, setExpiration] = useState('');

  useEffect(() => {
    if (option) {
      setSymbol(option.symbol);
      setType(option.type);
      setQuantity(option.quantity);
      setStrike(option.strike);
      setExpiration(option.expiration.toISOString().substring(0, 10));
    }
  }, [option]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ symbol, type, quantity: parseInt(quantity), strike: parseFloat(strike), expiration: new Date(expiration) });
  };

  const setSelectedStockAction = (e) => {
    const { value } = e.target;
    setSymbol(value);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ margin: '10px 0' }}></Box>
      <Select
        name="selectedStock"
        fullWidth
        labelId="stock-selector-label"
        value={symbol}
        onChange={setSelectedStockAction}
      >
        {stocks.map((stock) => (
          <MenuItem key={stock} value={stock}>{stock}</MenuItem>
        ))}
      </Select>
      <Box sx={{ margin: '10px 0' }}></Box>
      <Select
                name="direction"
                value={type}
                onChange={e => setType(e.target.value)}
                fullWidth
            >
                <MenuItem value="call">Call</MenuItem>
                <MenuItem value="put">Put</MenuItem>
            </Select>
     
      <Box sx={{ margin: '10px 0' }}></Box>
      <TextField label="Quantity" type="number" value={quantity} onChange={e => setQuantity(e.target.value)} required fullWidth />
      <Box sx={{ margin: '10px 0' }}></Box>
      <TextField label="Strike" type="number" value={strike} onChange={e => setStrike(e.target.value)} required fullWidth />
      <Box sx={{ margin: '10px 0' }}></Box>
      <TextField label="Expiration" type="date" value={expiration} onChange={e => setExpiration(e.target.value)} required fullWidth />
      <Box sx={{ margin: '10px 0' }}></Box>
      <Button type="submit" color="primary" variant="contained">Save</Button>
    </form>
  );
};

export default OptionForm;
