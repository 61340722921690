import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const VideoItemContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 20px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 180px; /* Default height for larger screens */
  object-fit: cover; /* Ensure the image covers the area without distorting its aspect ratio */
  border-bottom: 1px solid #ddd;

  @media (max-width: 768px) {
    height: 240px; /* Adjusted height for smaller screens */
  }
`;

const Title = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  color: #464853;
  font-size: 16px;
  line-height: 1.7em;

  @media (max-width: 768px) {
    font-size: 14px; /* Smaller font size for smaller screens */
    margin-left: 10px; /* Adjusted margin for smaller screens */
  }
`;

const SkeletonThumbnail = styled(Skeleton)`
  width: 100%;
  height: 180px; /* Default height for larger screens */

  @media (max-width: 768px) {
    height: 240px; /* Adjusted height for smaller screens */
  }
`;

const SkeletonTitle = styled(Skeleton)`
  margin-left: 20px;
  width: 200px;
  @media (max-width: 768px) {
    margin-left: 10px;
    width: 150px; /* Adjusted width for smaller screens */
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none; /* Remove underline */
`;

const VideoItem = ({ video }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate image loading
    const img = new Image();
    img.src = `https://i.ytimg.com/vi/${video.videoid}/sddefault.jpg`;
    img.onload = () => setIsLoading(false);
  }, [video.videoid]);

  return (
    <VideoItemContainer>
      <StyledLink to={`/video/${video.videoid}`}>
        {isLoading ? (
          <SkeletonThumbnail />
        ) : (
          <Thumbnail src={`https://i.ytimg.com/vi/${video.videoid}/sddefault.jpg`} alt={video.title} />
        )}
        <Title>
          {isLoading ? <SkeletonTitle /> : video.title}
        </Title>
      </StyledLink>
    </VideoItemContainer>
  );
};

export default VideoItem;
