import React, {useEffect} from 'react';
import { Canvas } from '@react-three/fiber';
import { Box, OrbitControls, Stars } from '@react-three/drei';
import SolarSystem from '../components/solarSystem/SolarSystem';
// import './styles/App.css';
import styled from 'styled-components';

const BoxContainer = styled(Box)`
  position: absolute;
    height: calc(100vh - 140px);
    width: 100vw;
    margin: 0;
    margin-top: -10px;
    overflow: hidden;
    @media screen and (max-width: 768px) {
      height: calc(100vh - 130px);
      margin-top: -20px;
    }
`;


function SolarSystemPage() {

  useEffect(() => {
    // Update the document title
    document.title = "Solar System"; // Set your desired title for the /options-simulator page
  }, []);

  return (
    <BoxContainer>
      <Canvas
        gl={{ antialias: true }}
        camera={{ position: [0, 5, 40], fov: 90 }}
        style={{ backgroundColor: '#000000' }}
      >
        {/* Ambient light provides a soft light around the scene */}
        <ambientLight intensity={0.5} />

        {/* Point light acts as a directional light source, like the sun */}
        <pointLight position={[10, 10, 10]} intensity={2} />

        {/* Grid Helper to visualize the ground plane */}
        {/* <gridHelper args={[1000, 1000]} position={[0, 0, 0]} /> */}

        {/* Controls to allow the user to interact with the 3D scene */}
        <OrbitControls />

        {/* Optional stars background */}
        <Stars />

        {/* Main component rendering the solar system */}
        <SolarSystem />
      </Canvas>
    </BoxContainer>
  );
}

export default SolarSystemPage;