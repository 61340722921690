import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, Box } from '@mui/material';

const stocks = ['AAPL', 'MSFT', 'GOOGL', 'AMZN', 'TSLA', 'FB', 'JPM', 'JNJ', 'V'];

const OptionForm = ({ initialOption, onSave, onCancel, setSelectedStock }) => {
    const [option, setOption] = useState({
        selectedStock: 'AAPL',
        strike: '',
        premium: '',
        quantity: '',
        type: 'call',
        direction: 'long',
        expiry: ''
    });

    useEffect(() => {
        if (initialOption) {
            setOption(initialOption);
        }
    }, [initialOption]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOption({ ...option, [name]: value });
    };

    const setSelectedStockAction = (e) => {
        const { name, value } = e.target;
        setOption({ ...option, [name]: value });
        setSelectedStock(value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(option);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Select
                name="selectedStock"
                fullWidth
                labelId="stock-selector-label"
                value={option.selectedStock}
                onChange={setSelectedStockAction}
            >
                {stocks.map((stock) => (
                    <MenuItem key={stock} value={stock}>{stock}</MenuItem>
                ))}
            </Select>
            <TextField
                name="strike"
                label="Strike Price"
                type="number"
                value={option.strike}
                onChange={handleChange}
                required
                fullWidth
            />
            <TextField
                name="premium"
                label="Premium"
                type="number"
                value={option.premium}
                onChange={handleChange}
                required
                fullWidth
            />
            <TextField
                name="quantity"
                label="Quantity"
                type="number"
                value={option.quantity}
                onChange={handleChange}
                required
                fullWidth
            />
            <Select
                name="type"
                value={option.type}
                onChange={handleChange}
                fullWidth
            >
                <MenuItem value="call">Call</MenuItem>
                <MenuItem value="put">Put</MenuItem>
            </Select>
            <Select
                name="direction"
                value={option.direction}
                onChange={handleChange}
                fullWidth
            >
                <MenuItem value="long">Long</MenuItem>
                <MenuItem value="short">Short</MenuItem>
            </Select>
            <TextField
                name="expiry"
                label="Expiry"
                type="date"
                value={option.expiry}
                onChange={handleChange}
                required
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button 
                    onClick={onCancel} 
                    variant="outlined" 
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button 
                    type="submit" 
                    variant="contained" 
                    color="primary"
                >
                    {initialOption ? 'Save' : 'Add'} Option
                </Button>
            </Box>
        </Box>
    );
};

export default OptionForm;