import React, { useMemo } from 'react';
import * as THREE from 'three';

const OrbitLine = ({ orbitRadius, segments = 64 }) => {
  // Create the orbit geometry using memoization to optimize performance
  const orbitGeometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry();
    const points = [];

    for (let i = 0; i <= segments; i++) {
      const angle = (i / segments) * 2 * Math.PI;
      points.push(new THREE.Vector3(Math.cos(angle) * orbitRadius, 0, Math.sin(angle) * orbitRadius));
    }

    geometry.setFromPoints(points);
    return geometry;
  }, [orbitRadius, segments]);

  return (
    <line geometry={orbitGeometry}>
      <lineBasicMaterial
        attach="material"
        color="lightgray" // Less bright color
        linewidth={0.5} // Thinner line for subtlety
        transparent
        opacity={0.6} // Make the line semi-transparent
        dashSize={0.1} // Size of the dash (if using dashed lines)
        gapSize={0.1} // Size of the gap (if using dashed lines)
      />
    </line>
  );
};

export default OrbitLine;