import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaYoutube, FaInstagram, FaLinkedin, FaEnvelope, FaUser } from 'react-icons/fa';
import HomePage from './pages/HomePage';
import LevelPage from './pages/LevelPage';
import CvPage from './pages/CvPage';
import VideoDetailsPage from './pages/VideoDetailPage';
import ContactPage from './pages/ContactPage';
import StockPage from './pages/StockPage';
import PairingPage from './pages/PairingPage';
import SolarSystemPage from './pages/SolarSystemPage';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Header = styled.header`
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled(Link)`
  font-size: 1.8em;
  font-weight: bold;
  color: #464853;
  font-family: 'Caveat', cursive;
  text-decoration: none;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: 768px) {
    font-size: 1.4em;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

const NavLink = styled(Link)`
  color: #464853;
  text-decoration: none;
  font-size: 1.1em;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    transform: translateY(-2px);
    color: #3498db;
  }
`;

const Body = styled.div`
  padding-top: 80px;
  flex: 1;
  background-color: #efebe9;
`;

const Footer = styled.footer`
  background: white;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

const SocialIcon = styled.a`
  color: #464853;
  font-size: 1.8em;
  transition: all 0.3s ease;

  &:hover {
    color: ${(props) => props.hoverColor};
    transform: translateY(-3px);
  }
`;

const App = () => {
  return (
    <Router>
      <AppContainer>
        <Header>
          <Logo to="/">Home</Logo>
          <NavLinks>
            <NavLink to="/cv">
              <FaUser />
              My Profile
            </NavLink>
            <NavLink to="/contact">
              <FaEnvelope />
              Contact Me
            </NavLink>
          </NavLinks>
        </Header>
        <Body>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/level/:domain/:level" element={<LevelPage />} />
            <Route path="/video/:videoid" element={<VideoDetailsPage />} />
            <Route path="/cv" element={<CvPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/options-simulator" element={<StockPage />} />
            <Route path="/pairing" element={<PairingPage />} />
            <Route path="/solar" element={<SolarSystemPage/>} />
          </Routes>
        </Body>
        <Footer>
          <SocialIcon href="https://www.youtube.com/channel/UCBh90Lm-3pJLUJrFCEfwCvQ" target="_blank" rel="noopener noreferrer" hoverColor="#FF0000">
            <FaYoutube />
          </SocialIcon>
          <SocialIcon href="https://www.instagram.com/aelhaouni/" target="_blank" rel="noopener noreferrer" hoverColor="#E4405F">
            <FaInstagram />
          </SocialIcon>
          <SocialIcon href="https://www.linkedin.com/in/amineelhaouni/" target="_blank" rel="noopener noreferrer" hoverColor="#0077B5">
            <FaLinkedin />
          </SocialIcon>
        </Footer>
      </AppContainer>
    </Router>
  );
};

export default App;
