import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { jsPDF } from "jspdf";
import { FaJava, FaMicrochip, FaExchangeAlt, FaDatabase, FaCloud, FaCode, FaNodeJs, FaAws, FaReact, FaUsers, FaGraduationCap } from 'react-icons/fa';
import { SiTypescript, SiNestjs, SiTerraform, SiSpringboot, SiMongodb, SiPostgresql } from 'react-icons/si';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const AboutSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  @media (min-width: 768px) {
    padding: 100px 50px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const PhotoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 40px;
  @media (min-width: 992px) {
    margin-bottom: 0;
    margin-right: 60px;
  }
`;

const Photo = styled.img`
  border-radius: 20px;
  width: 300px;
  height: 300px;
  object-fit: cover;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  animation: ${fadeIn} 1s ease-out;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  }
`;

const InfoContainer = styled.div`
  flex: 2;
  animation: ${slideIn} 1s ease-out;
`;

const Title = styled.h2`
  font-size: 3em;
  color: #2c3e50;
  margin-bottom: 15px;
  font-weight: 700;
`;

const Subtitle = styled.h3`
  font-size: 2em;
  color: #34495e;
  margin-bottom: 25px;
  font-weight: 500;
`;

const Paragraph = styled.p`
  font-size: 1.2em;
  line-height: 1.8em;
  color: #505a62;
  margin-bottom: 25px;
`;

const SectionTitle = styled.h4`
  font-size: 2em;
  color: #2c3e50;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
`;

const ExperienceItem = styled.div`
  margin-bottom: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const ExperienceHeader = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExperienceTitle = styled.h5`
  font-size: 1.5em;
  color: #2c3e50;
  margin: 0;
  font-weight: 600;
`;

const ExperienceDetails = styled.div`
  font-size: 1.1em;
  color: #34495e;
`;

const ExperienceContent = styled.div`
  padding: 20px;
  background-color: white;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const IconContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 25px;
  flex-wrap: wrap;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;

const Icon = styled.div`
  font-size: 2em;
  color: #3498db;
  margin-bottom: 5px;
`;

const IconLabel = styled.span`
  font-size: 0.9em;
  color: #34495e;
`;

const SkillList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  columns: 2;
  @media (max-width: 768px) {
    columns: 1;
  }
`;

const SkillItem = styled.li`
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #34495e;
`;

const DownloadButton = styled.button`
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #2980b9;
  }
`;

const AboutMe = () => {
  const [openExperience, setOpenExperience] = useState(0);

  const toggleExperience = (index) => {
    setOpenExperience(openExperience === index ? null : index);
  };

  const experiences = [
    {
      title: "Senior Software Engineer",
      company: "Public.com",
      period: "Jan 2023 - Present",
      location: "Copenhagen, Denmark",
      description: "Develop and maintain the trading platform for various securities (stocks, options, crypto, bonds, treasuries). Work on distributed systems with multiple services communicating through queues and API calls. Handle challenges related to high data loads and distributed systems.",
      skills: ["Java", "Spring Boot", "AWS ECS", "Terraform", "Distributed Systems", "High-Load Data Processing"],
      icons: [FaJava, SiSpringboot, FaAws, SiTerraform, FaMicrochip, FaDatabase]
    },
    {
      title: "Team Lead Engineer, Customer Delivery Team",
      company: "Penni.io",
      period: "Jul 2020 - Dec 2022",
      location: "Copenhagen, Denmark",
      description: "Managed a team of 10 people to develop and release products to insurance companies/partners. Responsible for developer careers, delivery deadlines, and architecture quality.",
      skills: ["Node.js", "NestJS", "TypeScript", "React.js", "AWS ECS", "Terraform", "Scrum"],
      icons: [FaNodeJs, SiNestjs, SiTypescript, FaReact, FaAws, SiTerraform, FaUsers]
    },
    {
      title: "Software Engineer",
      company: "GAN Integrity",
      period: "Nov 2017 – Jul 2020",
      location: "Copenhagen, Denmark",
      description: "Full stack development of GAN Integrity product, working on both backend and frontend of the application.",
      skills: ["Node.js", "React.js", "MongoDB", "Express", "Scrum", "AngularJS"],
      icons: [FaNodeJs, FaReact, SiMongodb]
    },
    {
      title: "Software Engineer JAVA/ReactJS",
      company: "KLEE GROUP",
      period: "Oct 2014 – Oct 2017",
      location: "Paris, France",
      description: "Developed CAP2ER, a Web Application for evaluating environmental impact of livestock operations that allows to collect and evaluate that.",
      skills: ["Java", "Node.js", "React.js", "PostgreSQL"],
      icons: [FaJava, FaNodeJs, FaReact, SiPostgresql]
    },
    {
      title: "Junior Computer Science Developer",
      company: "CAPGEMINI",
      period: "Feb 2013 - Sept 2014",
      location: "Nice, France",
      description: "Developed IT application for MECAPLAST that traces and manages the life cycle of cars parts.",
      skills: ["ASP.NET MVC4", "JavaScript", "UML", "Visual Studio"],
      icons: [FaCode, FaReact]
    }
  ];

  const education = [
    {
      degree: "Master degree in Telecommuncations and Network",
      institution: "INP ENSEEIHT, Toulouse",
      period: "2009–2012",
      description: "INP ENSEEIHT, Toulouse: A top ranking French engineering institute. Three year course specializing in Telecommunications and Networks leading to French Diploma in engineering."
    },
    {
      degree: "Classes préparatoires in Maths",
      // institution: "Not specified",
      period: "2007–2009",
      description: "Intensive courses to prepare for entrance to French Engineering Schools"
    },
    {
      degree: "Baccalaureate",
      // institution: "Not specified",
      period: "2006–2007",
      description: "Equivalent to 'A' levels in Mathematics, Physics and Natural Science"
    }
  ];

  const generatePDF = () => {
    const doc = new jsPDF();
    
    // Set font sizes
    const titleFont = 16;
    const subtitleFont = 14;
    const normalFont = 10;
    
    // Add content to PDF
    doc.setFontSize(titleFont);
    doc.text("Amine EL HAOUNI", 20, 20);
    
    doc.setFontSize(subtitleFont);
    doc.text("Senior Software Developer", 20, 30);
    
    doc.setFontSize(normalFont);
    let yPos = 40;
    
    // Summary
    doc.text("Summary:", 20, yPos);
    yPos += 10;
    const summary = "Software developer and team leader with over a decade of industry experience. Expertise in full stack development with lean to the backend, creating scalable architectures, and implementing efficient coding practices in fintech and insurtech domains.";
    const splitSummary = doc.splitTextToSize(summary, 170);
    doc.text(splitSummary, 20, yPos);
    yPos += splitSummary.length * 5 + 10;

    // Work Experience
    doc.setFontSize(subtitleFont);
    doc.text("Work Experience", 20, yPos);
    yPos += 10;
    doc.setFontSize(normalFont);
    
    experiences.forEach(exp => {
      doc.text(`${exp.title} - ${exp.company}`, 20, yPos);
      yPos += 5;
      doc.text(`${exp.period} | ${exp.location}`, 20, yPos);
      yPos += 5;
      const splitDesc = doc.splitTextToSize(exp.description, 170);
      doc.text(splitDesc, 20, yPos);
      yPos += splitDesc.length * 5 + 5;
      doc.text(`Skills: ${exp.skills.join(", ")}`, 20, yPos);
      yPos += 10;
    });

    // Education
    yPos += 10;
    doc.setFontSize(subtitleFont);
    doc.text("Education", 20, yPos);
    yPos += 10;
    doc.setFontSize(normalFont);
    
    education.forEach(edu => {
      doc.text(`${edu.degree} - ${edu.institution}`, 20, yPos);
      yPos += 5;
      doc.text(edu.period, 20, yPos);
      yPos += 5;
      const splitDesc = doc.splitTextToSize(edu.description, 170);
      doc.text(splitDesc, 20, yPos);
      yPos += splitDesc.length * 5 + 10;
    });

    doc.save("Amine_EL_HAOUNI_CV.pdf");
  };

  return (
    <AboutSection>
      <ContentWrapper>
        <PhotoContainer>
          <Photo src="images/profile.jpeg" alt="Amine EL HAOUNI" />
        </PhotoContainer>
        <InfoContainer>
          <Title>Amine EL HAOUNI</Title>
          <Subtitle>Senior Software Developer</Subtitle>
          <Paragraph>
            With over ten years of experience in software engineering, I've become a skilled developer and team leader. I specialize in full-stack development, focusing on scalable architectures and efficient coding practices in fintech and insurtech.          </Paragraph>
          <Paragraph>
          I enjoy using new technologies to create strong, scalable systems that help businesses grow and improve user experiences. My varied experience helps me connect technical work with strategic planning, ensuring top quality software solutions delivery.          </Paragraph>
          <SectionTitle>Work Experience</SectionTitle>
          {experiences.map((exp, index) => (
            <ExperienceItem key={index}>
              <ExperienceHeader onClick={() => toggleExperience(index)}>
                <div>
                  <ExperienceTitle>{exp.title}</ExperienceTitle>
                  <ExperienceDetails>{exp.company} | {exp.period}</ExperienceDetails>
                </div>
                <span>{openExperience === index ? '▲' : '▼'}</span>
              </ExperienceHeader>
              <ExperienceContent isOpen={openExperience === index}>
                <Paragraph>{exp.description}</Paragraph>
                <IconContainer>
                  {exp.icons.map((IconComponent, iconIndex) => (
                    <IconWrapper key={iconIndex}>
                      <Icon><IconComponent /></Icon>
                      <IconLabel>{exp.skills[iconIndex]}</IconLabel>
                    </IconWrapper>
                  ))}
                </IconContainer>
                <SkillList>
                  {exp.skills.map((skill, skillIndex) => (
                    <SkillItem key={skillIndex}>{skill}</SkillItem>
                  ))}
                </SkillList>
              </ExperienceContent>
            </ExperienceItem>
          ))}

          <SectionTitle>Education</SectionTitle>
          {education.map((edu, index) => (
            <ExperienceItem key={index}>
              <ExperienceHeader onClick={() => toggleExperience(index + experiences.length)}>
                <div>
                  <ExperienceTitle>{edu.degree}</ExperienceTitle>
                  <ExperienceDetails>{edu.institution} | {edu.period}</ExperienceDetails>
                </div>
                <span>{openExperience === index + experiences.length ? '▲' : '▼'}</span>
              </ExperienceHeader>
              <ExperienceContent isOpen={openExperience === index + experiences.length}>
                <Paragraph>{edu.description}</Paragraph>
                <IconContainer>
                  <IconWrapper>
                    <Icon><FaGraduationCap /></Icon>
                    <IconLabel>Education</IconLabel>
                  </IconWrapper>
                </IconContainer>
              </ExperienceContent>
            </ExperienceItem>
          ))}
        </InfoContainer>
      </ContentWrapper>
      <DownloadButton onClick={generatePDF}>
        Download CV as PDF
      </DownloadButton>
    </AboutSection>
  );
};

export default AboutMe;