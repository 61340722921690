import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
`;


const MainHeading = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;
  color: #0077b6;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 2em;
  }
`;

const SubHeading = styled.div`
  font-size: 2em;
  margin-bottom: 30px;
  color: #03045e;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const CourseList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
`;

const CourseItem = styled(Link)`
  padding-top: 20px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 10px;
  width: 300px;
  text-decoration: none;
  color: inherit;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const CourseImage = styled.img`
  width: 100%;
  height: auto;
`;

const CourseTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #464853;
  font-size: 16px;
  line-height: 1.7em;
`;

const HomePage = () => {
    return (
        <HomePageContainer>
            <MainHeading>Welcome to Algorithmics Mastery</MainHeading>
            <SubHeading>Master Algorithms & Prepare for Interviews</SubHeading>
            <CourseList>
                <CourseItem to="/level/algo/beginner">
                    <CourseImage src="/images/level-beginner.png" alt="Beginner Course" />
                </CourseItem>
                <CourseItem to="/level/algo/intermediate">
                    <CourseImage src="/images/level-medium.png" alt="Intermediate Course" />
                </CourseItem>
                <CourseItem to="/level/algo/advanced">
                    <CourseImage src="/images/level-hard.png" alt="Advanced Course" />
                </CourseItem>
                <CourseItem to="/level/database/beginner">
                        <CourseImage src="/images/database-beginner.png" alt="Database Course" />
                </CourseItem>
            </CourseList>
            <SubHeading>Personal projects</SubHeading>

            <CourseList>
                
                <CourseItem to="/options-simulator">
                    <CourseImage src="/images/options-strategies.png" alt="Options Strategy Simulator" />
                </CourseItem>

                <CourseItem to="/pairing">
                    <CourseImage src="/images/pairing.png" alt="Options pairing algorithm" />
                </CourseItem>
                <CourseItem to="/solar">
                    <CourseImage src="/images/solar.png" alt="Solar System Simulation" />
                </CourseItem>
            </CourseList>
        </HomePageContainer>
    );
};

export default HomePage;