import React, { useState } from 'react';
import { Button, Dialog, Box, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import StockForm from './StockForm';
import styled from 'styled-components';

const Title = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
  color: #464853;
  font-size: 26px;
  color: #0077b6;
`;


function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const StockList = ({ stocks, onUpdateStock, onDeleteStock, onAddStock }) => {
    const [open, setOpen] = useState(false);
    const [currentStock, setCurrentStock] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const handleClickOpen = (stock, index) => {
        setCurrentStock(stock);
        setCurrentIndex(index);
        setIsEditing(true);
        setOpen(true);
    };

    const handleAddClickOpen = () => {
        setCurrentStock({ symbol: '', quantity: 0, price: 0 });
        setCurrentIndex(null);
        setIsEditing(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentStock(null);
        setCurrentIndex(null);
    };

    const handleSave = (updatedStock) => {
        if (isEditing) {
            onUpdateStock(updatedStock, currentIndex);
        } else {
            updatedStock.id = getRandomInt();
            onAddStock(updatedStock);
        }
        handleClose();
    };

    return (
        <Box sx={{
            marginTop: '10px',
            paddingTop: '10px',
            backgroundColor: 'white',
            borderRadius: '10px'
        }}>
            <Title>Stocks</Title>
            <List>
                {stocks.map((stock, index) => (
                    <ListItem key={index} button onClick={() => handleClickOpen(stock, index)}>
                        <ListItemText primary={`${stock.symbol} ${stock.quantity} ${stock.price}`} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => onDeleteStock(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <Box sx={{
                padding: '10px'
            }}>
                <Button fullWidth variant="contained" color="primary" onClick={handleAddClickOpen}>Add Stock</Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{isEditing ? 'Edit Stock' : 'Add Stock'}</DialogTitle>
                <DialogContent>
                    {currentStock && <StockForm stock={currentStock} onSave={handleSave} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default StockList;
