import React, { useRef } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { TextureLoader } from 'three';
import { Text } from '@react-three/drei';
import OrbitLine from './OrbitLine';
import Moon from './Moon';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const randomStart = getRandomInt(0, 10000)


function Planet({ name, image, radius, orbitRadius, orbitTime, rotationSpeed = 0.02, moons = [] }) {
  const planetRef = useRef();
  const groupRef = useRef();
  const texture = useLoader(TextureLoader, image);

  // Convert orbitTime in minutes to orbitSpeed in radians per second
  const orbitSpeed = (2 * Math.PI) / (orbitTime * 60);

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime() + randomStart;

    if (planetRef.current) {
      // Self-rotation
      planetRef.current.rotation.y += rotationSpeed;
    }

    if (groupRef.current) {
      // Orbit around the Sun
      groupRef.current.position.x = orbitRadius * Math.cos(elapsedTime * orbitSpeed);
      groupRef.current.position.z = orbitRadius * Math.sin(elapsedTime * orbitSpeed);
    }
  });

  if (!texture) {
    return null;
  }

  return (
    <>
      <OrbitLine orbitRadius={orbitRadius} />
      <group ref={groupRef}>
        <mesh ref={planetRef}>
          <sphereGeometry args={[radius, 64, 64]} />
          <meshStandardMaterial
            map={texture}
            emissiveMap={texture}
            emissiveIntensity={0.5}
            emissive="white" />
        </mesh>
        <Text
          position={[0, radius + 1.5, 0]} // Position label above the planet
          fontSize={radius} // Adjust the font size based on planet radius
          color="white"
          anchorX="center"
          anchorY="middle"
        >
          {name}
        </Text>
        {moons.map((moon, index) => (
          <Moon
            key={index}
            name={moon.name}
            image={moon.image}
            radius={moon.radius}
            orbitRadius={moon.orbitRadius}
            orbitTime={moon.orbitTime}
            rotationSpeed={moon.rotationSpeed} />
        ))}
      </group>
    </>
  );
}

export default Planet;