// src/components/PairingOverview.js

import React from 'react';
import './PairingOverview.css'; // Import your CSS file for styling
import { Box } from '@mui/material';

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString(); // Formats date to a readable format
};

const formatOption = (option) => {
  return `${Math.abs(option.quantity)} X ${option.symbol} ${option.type} ${option.strike} (Exp: ${formatDate(option.expiration)})`;
};

const PairingOverview = ({ strategies, globalCash }) => {
  const renderPairs = (pairs) => {
    return pairs.map((pair, index) => (
      <tr key={index}>
        <td>
          {formatOption(pair.short)}
        </td>
        <td>
          {pair.stock ? `${pair.stock.quantity} X ${pair.stock.symbol} Stock @ ${pair.stock.price}` :
           pair.long ? formatOption(pair.long) :
           pair.cash ? `Cash: $${pair.cash}` : 'None'}
        </td>
      </tr>
    ));
  };

  return (
    <div >
      <h2>Pairing Overview</h2>
      {Object.keys(strategies).map(symbol => {
        if (symbol === 'remainingCash') return null;

        const strategy = strategies[symbol];

        return (
          <Box sx={{
            backgroundColor: 'white'
          }} key={symbol} className="strategy-section">
            <h3>{symbol} Strategy</h3>
            <h4>Pairs</h4>
            <table>
              <thead>
                <tr>
                  <th>Short Option</th>
                  <th>Coverage</th>
                </tr>
              </thead>
              <tbody>
                {renderPairs(strategy.pairs)}
              </tbody>
            </table>

            <h4>Not paired</h4>
            <ul>
              {strategy.unpaired.length > 0 ? (
                strategy.unpaired.map((short, index) => (
                  <li key={index}>
                    {formatOption(short)}
                  </li>
                ))
              ) : (
                <li>No upaired option</li>
              )}
            </ul>

            <h4>Naked Shorts</h4>
            <ul>
              {strategy.nakedShorts.length > 0 ? (
                strategy.nakedShorts.map((short, index) => (
                  <li key={index}>
                    {formatOption(short)}
                  </li>
                ))
              ) : (
                <li>No naked shorts</li>
              )}
            </ul>

            <h4>Stock Position</h4>
            <p>{strategy.stock.quantity} X {strategy.stock.symbol}</p>
          </Box>
        );
      })}

      <Box sx={{
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '10px',
            marginBottom: '10px'

      }}>
        
        <h3>Remaining Cash</h3>
        <p>${globalCash}</p>
        </Box>
    </div>
  );
};

export default PairingOverview;
