import React, { useState } from 'react';
import { predefinedStrategies } from './useOptionChart';
import { Modal, Box, Button, Typography, Grid } from '@mui/material';


export const StrategySelector = ({ onSelectStrategy }) => {
    // const [selectedStrategy, setSelectedStrategy] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleStrategyClick = (strategyName) => {
        // setSelectedStrategy(strategyName);
        onSelectStrategy(predefinedStrategies[strategyName] || []);
        setShowModal(false);
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Box sx={{
            marginTop: '15px',
            // border: 'solid',
        }}>
            <Button fullWidth variant="contained" onClick={handleShowModal}>
                Select a Strategy
            </Button>

            <Modal
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        maxHeight: '80vh',
                        overflowY: 'auto'
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Select a Strategy
                    </Typography>
                    <Grid container spacing={2}>
                        {Object.keys(predefinedStrategies).map((strategy) => (
                            <Grid item xs={12} sm={6} md={4} key={strategy}>
                                <Box
                                    onClick={() => handleStrategyClick(strategy)}
                                    sx={{
                                        border: '1px solid #ccc',
                                        padding: '20px',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        ':hover' : {
                                            backgroundColor: '#d3d3d3',// : '#fff',

                                        }
                                    }}
                                >
                                    <Typography variant="subtitle1" component="div">
                                        {strategy}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Modal>
        </Box>
    );
};
