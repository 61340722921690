import React, { useState, useMemo } from 'react';
import { Box, Typography, Button, Modal, FormControlLabel, Checkbox } from '@mui/material';
import OptionChart from './OptionsChart';
import OptionsList from './OptionsList';
import OptionForm from '../OptionForm';
import { StrategySelector } from './StrategySelector';
import Slider from '@mui/material/Slider';
import dayjs from 'dayjs';

const PLChart = ({ setOptions, options, updateOption, deleteOption, addOption, selectedStock, setSelectedStock }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingOption, setEditingOption] = useState(null);
    const [showOptions, setShowOptions] = useState(false);
    const [showCumulative, setShowCumulative] = useState(true);  // new state for cumulative
    const [sliderValue, setSliderValue] = useState([0, 100]); // Initial slider value

    const handleOpenModal = (option = null) => {
        setEditingOption(option);
        setIsModalOpen(true);
    };

    const handleStrategySelect = (strategyOptions) => {
        setOptions(strategyOptions);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditingOption(null);
    };

    const handleSaveOption = (optionData) => {
        if (editingOption) {
            updateOption({ ...optionData, id: editingOption.id });
        } else {
            addOption(optionData);
        }
        handleCloseModal();
    };

    const toggleOptionVisibility = (id) => {
        updateOption(options.map(option =>
            option.id === id ? { ...option, visible: !option.visible } : option
        ));
    };

    // Extract expiration dates from options and create a unique sorted list
    const expirationDates = useMemo(() => {
        const dates = options.map(option => dayjs(option.expiry).valueOf());
        const uniqueSortedDates = [...new Set(dates)].sort((a, b) => a - b);

        if (uniqueSortedDates.length === 0) return [];

        const edgePadding = 24 * 60 * 60 * 1000; // 1 day in milliseconds
        const paddedDates = [];

        for (let i = 0; i < uniqueSortedDates.length; i++) {
            if (i === 0) {
                paddedDates.push(uniqueSortedDates[i] - edgePadding);
            } else {
                const midPoint = (uniqueSortedDates[i - 1] + uniqueSortedDates[i]) / 2;
                paddedDates.push(midPoint);
            }
            paddedDates.push(uniqueSortedDates[i]);
            if (i === uniqueSortedDates.length - 1) {
                paddedDates.push(uniqueSortedDates[i] + edgePadding);
            }
        }

        return paddedDates;
    }, [options]);
    // Create marks for the slider based on the expiration dates
    const marks = expirationDates.map((date, index) => ({
        value: index * (100 / (expirationDates.length - 1)),
        label: dayjs(date).format('MMM D, YYYY'),
    }));

    // Function to map slider value to date range
    const sliderToDateRange = (sliderValue) => {
        const startIndex = Math.round(sliderValue[0] * (expirationDates.length - 1) / 100);
        const endIndex = Math.round(sliderValue[1] * (expirationDates.length - 1) / 100);
        return [expirationDates[startIndex], expirationDates[endIndex]];
    };

    // Filter options based on the selected slider value
    const filteredOptions = options.filter(option => {
        const optionDate = dayjs(option.expiry).valueOf();
        const [startDate, endDate] = sliderToDateRange(sliderValue);
        return optionDate >= startDate && optionDate <= endDate;
    });

    return (
        <Box>
            {options.length > 0 ?
                (<OptionChart options={filteredOptions} selectedStock={selectedStock} showOptions={showOptions} showCumulative={showCumulative} />)
                : (
                    <Box sx={{ mt: 4, textAlign: 'center' }}>
                        <Typography variant="h5" color="textSecondary">
                            No options or strategy selected yet.
                        </Typography>
                    </Box>
                )}
            {expirationDates.length > 0 && (
                <Box sx={{
                    // border: 'solid',
                    width: 'calc(100% - 20px)',
                    margin: 'auto',
                }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showOptions}
                                onChange={(e) => setShowOptions(e.target.checked)}
                            />
                        }
                        label="Show Options"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showCumulative}
                                onChange={(e) => setShowCumulative(e.target.checked)}
                            />
                        }
                        label="Show Cumulative"
                    />
                </Box>)}
            {expirationDates.length > 0 && (
                <Box sx={{
                    width: 'calc(100% - 40px)',
                    margin: 'auto',
                    // border: 'solid'
                }}>
                    <Slider
                        value={sliderValue}
                        onChange={(event, newValue) => setSliderValue(newValue)}
                        valueLabelDisplay="auto"
                        marks={marks}
                        min={0}
                        max={100}
                        step={null}
                        valueLabelFormat={(value) => {
                            const index = Math.round(value * (expirationDates.length - 1) / 100);
                            return dayjs(expirationDates[index]).format('MMM D, YYYY');
                        }}
                        sx={{ mt: 4 }}
                    />
                </Box>
            )}
            {filteredOptions.length > 0 && <Typography variant="h6" gutterBottom>
                Options
            </Typography>}
            <OptionsList
                options={filteredOptions}
                onEditOption={handleOpenModal}
                updateOption={updateOption}
                deleteOption={deleteOption}
                selectedStock={selectedStock}
                toggleOptionVisibility={toggleOptionVisibility}
            />
            <Box
                sx={{
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center'
                }}
            >
                <Button
                    onClick={() => handleOpenModal()}
                    variant="contained"
                    color="primary"
                    sx={{
                        mt: 2,
                        flexGrow: 1
                    }}
                >
                    Add Option
                </Button>
                <Box sx={{ flexGrow: 1 }}>
                    <StrategySelector onSelectStrategy={handleStrategySelect} />
                </Box>
            </Box>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="option-modal"
                aria-describedby="modal-to-add-or-edit-option"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="option-modal" variant="h6" component="h2" gutterBottom>
                        {editingOption ? 'Edit Option' : 'Add New Option'}
                    </Typography>
                    <OptionForm
                        initialOption={editingOption}
                        onSave={handleSaveOption}
                        onCancel={handleCloseModal}
                        setSelectedStock={setSelectedStock}
                    />
                </Box>
            </Modal>
        </Box>
    );
};

export default PLChart;
