import React from 'react';
import { Box } from '@mui/material';
import useOptionChart from './useOptionChart';

const OptionChart = ({ options, selectedStock, showOptions, showCumulative }) => {
    const chartRef = useOptionChart(options, selectedStock, showOptions, showCumulative);

    return (
        <Box sx={{
            // Adjust parent Box height for better responsiveness
            height: '100%',
            width: '100%',
            minHeight: '300px',
        }}>
            <canvas ref={chartRef} style={{  display: 'block', width: '100%', minHeight: '100%' }} />
        </Box>
    );
};

export default OptionChart;
